












































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { filters } from '@/components/filters/filters-config';
import DashboardApi from '@/services/dashboard';
import SourceApi from '@/services/source';
import { FilterConfig } from '@/models/filters-config';
import { DashboardConfiguration, EnumPanelType, Panel } from '@/models/dashboard';
import { Variable } from '@/models/variable';
import { Source } from '@/models/source';
import { calculatePositionForNewPanel } from '@/helpers/grid';
import store from '@/store';

const extraFieldsFormComponents = filters
  .reduce((obj, item) => Object.assign(obj, { [`${item.type}_extra_fields_form`]: () => item.extraFieldsFormComponent }), {});

@Component({
  components: {
    ...extraFieldsFormComponents,
  },
})
export default class AddVariable extends Vue {
  dashboardApi = new DashboardApi();

  sourceApi = new SourceApi();

  mode = ''; // TODO

  availableSources: Source[] | null = null;

  selectedSource: Source | null = null;

  selectedFilterType = '';

  queryResult: any | null = null;

  dashboard: DashboardConfiguration | null = null;

  filtersConfig: FilterConfig[] = filters;

  newVariable: Variable = {
    id: 0,
    name: '',
    label: '',
    sourceUUID: '',
    query: '',
    filterType: '',
    filterPosition: 'top',
    filterData: {
      valuesField: '',
      labelsField: '',
    },
  };

  newPanel: Panel | null = null;

  created(): void {
    // todo: Promise.all ?
    store.dispatch('setLoading', true);

    this.sourceApi.findAll().then((response) => {
      if (response.success) {
        this.availableSources = response.result;
      } else {
        console.log('err', response);
      }
    });

    const dashboardId = parseInt(this.$route.params.id, 10);

    this.dashboardApi.findOne(dashboardId, false).then((response) => {
      if (response.success) {
        this.dashboard = response.result;

        this.newVariable = {
          ...this.newVariable,
          // eslint-disable-next-line
          id: this.dashboard.variables && this.dashboard.variables.length ? Math.max.apply(Math, this.dashboard.variables.map((x) => x.id)) + 1 : 1,
        };
      } else {
        console.log('err', response);
      }
    }).catch((err) => {
      console.log('err');
    }).finally(() => {
      store.dispatch('setLoading', false);
    });
  }

  @Watch('selectedSource', { deep: true })
  onSelectedSourceChange(selectedSource: Source): void {
    this.newVariable.sourceUUID = selectedSource.uuid;
  }

  @Watch('selectedFilterType')
  onNewVariableChange(selectedFilterType: string) {
    console.log('filter type', selectedFilterType);
    this.newVariable.filterType = selectedFilterType;

    if (this.filtersConfig.find((x) => x.type === selectedFilterType)?.isPanel) {
      this.newVariable.filterPosition = 'panel';
      this.newPanel = {
        // eslint-disable-next-line
        id: this.dashboard!.panels && this.dashboard!.panels.length ? Math.max.apply(Math, this.dashboard!.panels.map((x) => x.id)) + 1 : 1,
        type: EnumPanelType.FILTER,
        data: { variableID: this.newVariable.id },
        position: calculatePositionForNewPanel(this.dashboard!),
      };
    } else {
      this.newVariable.filterPosition = 'top';
      this.newPanel = null;
    }
  }

  get tableFields() {
    return Object.keys(this.queryResult[0]).map((x) => ({ text: x, value: x }));
  }

  executeQuery(): void {
    this.sourceApi.query(this.selectedSource!.uuid, this.newVariable.query, false).then((response) => {
      if (response.success) {
        this.queryResult = response.result;
      } else {
        console.log('err', response);
      }
    });
  }

  createVariable(): void {
    store.dispatch('setLoading', true);

    this.dashboardApi.update(this.dashboard!.id, {
      ...this.dashboard!,
      // eslint-disable-next-line
      panels: this.dashboard?.panels && this.dashboard?.panels?.length && this.newPanel
        ? this.dashboard!.panels?.concat([this.newPanel])
        // eslint-disable-next-line
        : this.newPanel 
          ? [this.newPanel]
          : this.dashboard!.panels
            ? this.dashboard!.panels
            : [],
      variables: this.dashboard!.variables && this.dashboard!.variables.length
        ? this.dashboard!.variables.concat([this.newVariable])
        : [this.newVariable],
    }).then((response) => {
      if (response.success) {
        this.$router.push(`/dashboard/${this.$route.params.id}/settings/variables`);
      } else {
        console.log('err', response);
        store.dispatch('setLoading', false);
      }
    }).catch((err) => {
      console.log('err');
      store.dispatch('setLoading', false);
    });
  }

  onFilterDataChange(data: any): void {
    console.log('qwe', data);
    this.newVariable.filterData = {
      ...this.newVariable.filterData,
      ...data,
    };
  }
}
